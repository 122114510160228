import React, { useEffect, useState } from "react";
import { CircularProgress, CssBaseline, Fab, Grid, Typography } from "@mui/material";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { GET_VEHICLES_TO_DEFLEET } from "../../../../graphql/Defleet/defleetQuery";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { MUIDataTableOptions } from "mui-datatables";
import { SelectableRows } from "../../../common/NuvvenTable/types";
import { IVehicle, IVehicleDefleet } from "../../../../reducers/fleet/types";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { CsvUploadStatusType, formatGraphQLErrorMessage, ICsvUploadError, possibleCsvMimeTypes, vehicleDefleetUploadCsvHearders } from "../../../common/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { captureErrorException } from "../../../../utils/sentry";
import papa from "papaparse";
import { BulkCsvUploadDialog } from "../../../common/BulkCsvUploadDialog/BulkCsvUploadDialog";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { UPLOAD_DEFLEET_VEHICLES } from "../../../../graphql/Defleet/uploadVehicleDeFleetMutation";
import { SEARCH_DEFLEET_VEHICLES } from "../../../../graphql/Defleet/searchDeFleetVehicle";
import _ from "lodash";
import { DateTime as d } from "luxon";
import { initialDeFleetCheckList, initialVehicleDefleet } from "./DefleetVehicle";
import { deFleetReasons, DeFleetReasons } from "../utils";


export const Defleet: React.FC = () => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const location = useLocation();
  const [fileValue, setFileValue] = useState<File | undefined>();
  const [uploadStatus, setUploadStatus] = useState<number>(
    CsvUploadStatusType.DEFAULT
  )
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const [uploadErrors, setUploadErrors] = useState<ICsvUploadError[]>([]);
  const [fileName, setFileName] = useState<string>("");
  const [rows, setRows] = useState([]);

  const [uploadDeFleetvehicles] = useMutation(UPLOAD_DEFLEET_VEHICLES, {
    onError: (error: ApolloError) => {
      setFileName("");
      setFileValue(undefined);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: (data) => {
      if (
        data.uploadDefleetVehicles &&
        data.uploadDefleetVehicles.errors &&
        data.uploadDefleetVehicles.errors.length
      ) {
        setFileName("");
        setFileValue(undefined);
        setUploadErrors(data.uploadDefleetVehicles.errors);
        return setUploadStatus(CsvUploadStatusType.ERROR);
      }
      snackbar({
        message: "DeFleet vehicles uploaded successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setUploadModalOpen(false);
      setUploadStatus(CsvUploadStatusType.DEFAULT);
      vehicles();
      setFileName("");
      setFileValue(undefined);
    }
  })

  const [searchDefleetVehicles, { loading: searchVehicleLoading }] = useLazyQuery(
    SEARCH_DEFLEET_VEHICLES,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data && data.searchDeFleetVehicles) {
          const newVehicles = data.searchDeFleetVehicles.map((vehicle: IVehicle) =>
            reshapeDefleetData(vehicle)
          );
          setRows(newVehicles);
        }
      }
    }
  );

  const [vehicles, { loading: vehicleLoading }] = useLazyQuery(GET_VEHICLES_TO_DEFLEET, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.getVehiclesToDefleet) {
        console.log("abc..", data.getVehiclesToDefleet);
        const reshapedData = data.getVehiclesToDefleet.map((vehicle: IVehicle) => reshapeDefleetData(vehicle))
        setRows(reshapedData)
      }
    }
  });
  useEffect(() => {
    if (userState.tenancy) {
      vehicles();
    }
  }, [userState]);

  useEffect(() => {
    if (location && location.search) {
      vehicles();
    }
  }, [location]);

  function reshapeDefleetData(defleetData: IVehicle) {
    const today = d.now();
    let alertBy: DeFleetReasons | string = "";

    const getDefleetReasonLabel = (value: DeFleetReasons): string => {
      const reason = deFleetReasons.find(reason => reason.value === value);
      return reason ? reason.label : value;
    };

    if (defleetData?.deFleetReason) {
      alertBy = getDefleetReasonLabel(defleetData.deFleetReason as DeFleetReasons);
    } else if (!alertBy && !defleetData.deFleetReason) {
      if (defleetData.deFleetParameters?.byDate) {
        const defleetDate = d.fromISO(defleetData.deFleetParameters.byDate);
        const alertStartDate = defleetDate.minus({ months: 3 });

        if (today >= alertStartDate) {
          alertBy = getDefleetReasonLabel(DeFleetReasons.DURATION_LIMIT);
        }
      }

      if (!alertBy && defleetData.deFleetParameters?.byDistanceDriven && defleetData.deFleetParameters?.alertBeforeDistance) {
        const defleetTriggerPointByDistance = defleetData.deFleetParameters.byDistanceDriven - defleetData.deFleetParameters.alertBeforeDistance;

        if (defleetData.vehicleState.odometerReading >= defleetTriggerPointByDistance) {
          alertBy = getDefleetReasonLabel(DeFleetReasons.MILEAGE);
        }
      }
    }

    return {
      id: defleetData.id,
      vehicle: defleetData.licencePlate,
      deFleetionReason: alertBy
    };
  }


  const handleSearch = _.debounce((searchText: string) => {
    if (searchText) {
      searchDefleetVehicles({
        variables: {
          q: searchText
        }
      })
    }
  }, 1000);

  function onSelectDocument(event: any) {
    const file: File = event.target.files[0];
    setUploadErrors([]);
    setUploadStatus(CsvUploadStatusType.DEFAULT);
    if (file) {
      setFileName(file.name);
      if (possibleCsvMimeTypes.includes(file.type)) {
        setFileValue(file);
      } else {
        return snackbar({
          message: "Invalid File Type. Allowed type: csv",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  function uploadCsv() {
    try {
      if (fileValue) {
        setUploadStatus(CsvUploadStatusType.IN_PROGRESS);
        papa.parse(fileValue, {
          skipEmptyLines: true,
          complete(results: any) {
            const deFleetvehicles = [];
            if (results.data.length) {
              for (let index = 0; index < results.data[0].length; index++) {
                const header = results.data[0][index];
                if (header !== vehicleDefleetUploadCsvHearders[index]) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `Invalid File Header: "${header}" is incorrect.`,
                    variant: SnackBarVariant.ERROR
                  });
                }
                if (results.data.length === 2 && !results.data[1][0]) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: "License plate is missing.",
                    variant: SnackBarVariant.ERROR
                  });
                }
                for (let index = 1; index < results.data.length; index++) {
                  const element: any = results.data[index];
                  if (element.length < 3) {
                    setUploadStatus(CsvUploadStatusType.ERROR);
                    setFileName("");
                    setFileValue(undefined);
                    return snackbar({
                      message: "Invalid file",
                      variant: SnackBarVariant.ERROR
                    });
                  }
                  if (!element[0]) {
                    setUploadStatus(CsvUploadStatusType.ERROR);
                    setFileName("");
                    setFileValue(undefined);
                    return snackbar({
                      message: `Licence plate is missing at row ${index}`,
                      variant: SnackBarVariant.ERROR
                    })
                  }
                  if (!element[1]) {
                    setUploadStatus(CsvUploadStatusType.ERROR);
                    setFileName("");
                    setFileValue(undefined);
                    return snackbar({
                      message: `Residual value is missing at row ${index}`,
                      variant: SnackBarVariant.ERROR
                    })
                  }
                  if (!element[10]) {
                    setUploadStatus(CsvUploadStatusType.ERROR);
                    setFileName("");
                    setFileValue(undefined);
                    return snackbar({
                      message: `Both keys data is missing at row ${index}`,
                      variant: SnackBarVariant.ERROR
                    })
                  }
                  if (!element[17]) {
                    setUploadStatus(CsvUploadStatusType.ERROR);
                    setFileName("");
                    setFileValue(undefined);
                    return snackbar({
                      message: `De-fleet price is missing at row ${index}`,
                      variant: SnackBarVariant.ERROR
                    })
                  }
                  if (element[10] !== "1" && element[10] !== "2") {
                    setUploadStatus(CsvUploadStatusType.ERROR);
                    setFileName("");
                    setFileValue(undefined);
                    return snackbar({
                      message: `Both keys value is incorrect at row ${index}`,
                      variant: SnackBarVariant.ERROR
                    });
                  }
                  const updatedData = {
                    licencePlate: element[0].trim(),
                    residualValue: element[1] ? parseInt(element[1]) : 0,
                    namaGrading: element[16].trim(),
                    deFleetPrice: element[17] ? parseInt(element[17]) : 0,
                    deFleetCheckList: {
                      ...initialDeFleetCheckList,
                      exteriorPaint: element[2].trim(),
                      wheelRims: element[3].trim(),
                      windscreensAndWindows: element[4],
                      mirrors: element[5].trim(),
                      bumper: element[6].trim(),
                      headlamps: element[7].trim(),
                      interiorFabric: element[8].trim(),
                      locksAndDoorHandles: element[9].trim(),
                      bothKeys: element[10].trim(),
                      telematicsDeviceRemoved: element[11].trim(),
                      pcn: element[12].trim(),
                      pcnTotalAmount: element[13] ? parseInt(element[13]) : 0,
                      loanStatus: element[14].trim(),
                      licencePlateCondition: element[15].trim()
                    }
                  };
                  deFleetvehicles.push(updatedData);
                }
                if (deFleetvehicles.length) {
                  uploadDeFleetvehicles({
                    variables: {
                      deFleetvehicles
                    }
                  });
                } else {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  snackbar({
                    message: "No data found.",
                    variant: SnackBarVariant.ERROR
                  });
                }
              }
            }
          }
        })
      }

    } catch (error) {
      setUploadStatus(CsvUploadStatusType.ERROR);
      captureErrorException(error)
    }
  }

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    rowHover: true,
    elevation: 0,
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: vehicleLoading || searchVehicleLoading ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      }
    },
    onRowClick: (
      rowData: string[],
      rowMeta: { dataIndex: number, rowIndex: number }
    ) => {
      const row: IVehicleDefleet = rows[rowMeta.dataIndex];
      if (row && row?.id) {
        navigate(`/update-vehicle-defleet?id=${row?.id}`);
      }
    },
    responsive: "scroll" as any,
    onSearchChange: (searchText: string | null) => {
      if (searchText) {
        setRows([])
        handleSearch(searchText);
      }
    }
  };

  const defleetTableColumn: any = [
    {
      label: "Vehicle",
      name: "vehicle"
    },
    {
      label: "Vehicle De-Fleet Reason",
      name: "deFleetionReason"
    }
  ];

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6}>
        <Typography variant="h1" color="primary">
          Vehicle De-Fleet
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <Fab
          variant="extended"
          size="medium"
          aria-label="Defleet Multiple"
          style={{ marginLeft: 10 }}
          onClick={() => setUploadModalOpen(true)}
        >
          De-Fleet Multiple
        </Fab>
      </Grid>
      <Grid container item xs={12}>
        {!vehicleLoading ? (
          <NuvvenTable
            title=""
            rows={rows}
            columns={defleetTableColumn}
            options={options}
            setSelection={() => { }}
          />
        ) : (
          <CircularProgress />
        )}
      </Grid>
      {uploadModalOpen && (
        <BulkCsvUploadDialog
          title={"Create multiple defleet vehicle"}
          subTitle={"Vehicle Defleet"}
          csvTemplateLink={"https://nuvvenassets.s3.eu-west-2.amazonaws.com/downloads/Coastr-Vehicle-Defleet-Upload.csv"}
          uploadErrors={uploadErrors}
          uploadStatus={uploadStatus}
          onDocumentSelect={onSelectDocument}
          fileName={fileName}
          uploadCsv={uploadCsv}
          handleClose={() => setUploadModalOpen(false)}
        />
      )}
    </Grid>
  )
}