import "flatpickr/dist/themes/material_green.css";

import {
  Box,
  Checkbox,
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  TextField as InputField,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { GET_AVAILABLE_VEHICLE_GROUPS } from "../../../../../graphql/bookings/getAvailableVehicleGroups";
import { GET_BUSINESS_CUSTOMERS } from "../../../../../graphql/businessCustomers/businessCustomer";
import { GET_BRANCH } from "../../../../../graphql/organisation/getBranch";
import {
  IBookingBusinessCustomer,
  IBookingCreateInput
} from "../../../../../reducers/bookings/types";
import { IServiceLocation } from "../../../../../reducers/user/types";
import { IRate, IVehiclePriceGroup } from "../../../../../reducers/vehiclePriceGroups/types";
import { IAppState } from "../../../../../store";
import { DateTimePicker } from "../../../../common/DateTimePicker";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { BookingStatus, capitalizeFirstLetter, customerTypes, getEndDateForBilling, rateTypes, RATE_TYPES, getSearchButtonGridSize } from "../../utils";
import VehicleSelection from "../VehicleSelection/VehicleSelection";
import { IPriceRule } from "../../../../../reducers/priceRule/types";
import { GET_EXTERNAL_AVAILABLE_VEHICLE_GROUPS } from "../../../../../graphql/bookings/getExternalAvailableVehicleGroups";
import { DateTime as d } from "luxon"
import { ILocationSurchargeInput } from "../../../../../reducers/organisation/types";
import { UserRoles } from "../../../../hoc/Authorization";
import { getLocalizedBookingSyntex, getLocalizedDateFormat, getLocalizedDateFormatString } from "../../../../../utils/localized.syntex";
import { DATE_TYPE } from "../../../../common/utils";
import { IVehicle } from "../../../../../reducers/fleet/types";
import { useLazyQuery } from "@apollo/client";
import { GET_SERVICE_LOCATIONS_IN_ORG } from "../../../../../graphql/vehicleMovement/queries";
import _ from "lodash";
import { IOneWayRentalFee } from "../../../VehicleMovement/types";
import { BookingType } from "../../../AppSettings/types";
import { GET_AVAILABLE_SUBSCRIPTION_VEHICLES } from "../../../../../graphql/bookings/getVailableSubscriptionVehiclesQuery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      "& .MuiGrid-root": {
        padding: theme.spacing(1, 1)
      }
    },
    formBtn: {
      backgroundColor: "black",
      border: "1px solid #ccc",
      borderRadius: 26,
      color: "white",
      cursor: "pointer",
      minHeight: 54,
      minWidth: 156
    },
    header: {
      fontSize: 16,
      fontWeight: "bold",
      marginBottom: 10
    },
    root: {
      padding: theme.spacing(2, 2),
      width: "100%"
    },
    clearSelect: {
      backgroundColor: '#fff',
      padding: 0,
      zIndex: 2,
      marginRight: '-5px',
      '&:hover': {
        backgroundColor: '#fff'
      }
    }
  })
);

interface IProps {
  bookingData: IBookingCreateInput;
  vehicleGroupsData: IVehiclePriceGroup[];
  locationSurchargesData: ILocationSurchargeInput[];
  oneWayRentalFee: IOneWayRentalFee | undefined;
  updateVehicleGroups: (data: IVehiclePriceGroup[]) => void;
  updateLocationSurcharge: (data: ILocationSurchargeInput[]) => void;
  updateOneWayRentalFee: (data: IOneWayRentalFee) => void;
  isUpdate: boolean;
  onSubmit: (data: IBookingCreateInput, updateOnly?: boolean) => void;
  bookingType: string;
  setBookingType: (bookingType: string) => void;
  updateSubscriptionVehicles: (vehicles: IVehicle[]) => void;
  subscriptionVehicles: IVehicle[];
}

export interface IVehicesDistance {
  vehicleId: string;
  distance: number;
}

const BaseDetails: React.FC<IProps> = (props) => {
  const { bookingType, setBookingType } = props
  const classes = useStyles();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { allowSubscriptionBookings } = userState.currentOrganisation
  const { country } = userState.currentOrganisation.address;
  const [differentDropOffLocation, setDifferentDropOffLocation] = useState<
    boolean
  >(false);
  const [isPriceZero, setIsPriceZero] = useState<boolean>(false);
  const [values, setValues] = useState<IBookingCreateInput>(props.bookingData);
  const [vehicleGroups, setVehicleGroups] = useState<IVehiclePriceGroup[]>([]);
  const [ownVehicleGroups, setOwnVehicleGroups] = useState<IVehiclePriceGroup[]>([]);
  const [externalVehicleGroups, setExternalVehicleGroups] = useState<IVehiclePriceGroup[]>([]);
  const [locationSurchargeRules, setLocationSurchargeRules] = useState<ILocationSurchargeInput[]>([]);
  const [vehiclesSearched, setVehiclesSearched] = useState<boolean>(false);
  const [requiredFieldUpdated, setRequiredFieldUpdated] = useState<boolean>(
    false
  );
  const [searchDisabled, setSearchDisabled] = useState<boolean>(false)
  const [toDateSelected, setToDateSelected] = useState<boolean>(false);
  const [businessCustomer, setBusinessCustomer] = useState<
    IBookingBusinessCustomer | undefined
  >();
  const [businessCustomers, setBusinessCustomers] = useState<
    IBookingBusinessCustomer[]
  >([]);
  const [tempdate, setTempDate] = useState<string>('')
  const [serviceLocations, setServiceLocations] = useState<IServiceLocation[]>([]);
  const [oneWayRentalFee, setOneWayRentalFee] = useState<IOneWayRentalFee>();
  const [subscriptionVehicles, setSubscriptionVehicles] = useState<IVehicle[]>([])
  const [isServiceLocationDisabled, setIsServiceLocationDisabled] = useState<boolean>(false);

  const [loadBranchData, { data: branchData }] = useLazyQuery(GET_BRANCH, {
    fetchPolicy: "no-cache",
    onCompleted: (branchData) => {
      if (branchData && branchData.branch && branchData.branch.activeLocations) {
        setServiceLocations(branchData.branch.activeLocations)
      }
    }
  });

  const [loadServiceLocationInOrg, { loading: loadingServiceLocationInOrg, data: serviceLocationsData }] = useLazyQuery(GET_SERVICE_LOCATIONS_IN_ORG, {
    fetchPolicy: "no-cache",
    onCompleted: (serviceLocationsData) => {
      if (serviceLocationsData && serviceLocationsData.getServiceLocations) {
        if (props.bookingData && props.bookingData?.id) {
          const serviceLocationFound = serviceLocationsData.getServiceLocations.some(
            (location: IServiceLocation) => location.id === props.bookingData.pickupServiceLocation
          );
          if (!serviceLocationFound) {
            setIsServiceLocationDisabled(true)
          }
        }
        setServiceLocations(serviceLocationsData.getServiceLocations)
      }
    }
  });

  const [loadBusinessCustomers, { data: businessCustomersData }] = useLazyQuery(
    GET_BUSINESS_CUSTOMERS,
    {
      fetchPolicy: "no-cache"
    }
  );

  const [
    loadVehicleGroups,
    { loading: vehicleGroupsLoading, data: availableVehicleGroupsData }
  ] = useLazyQuery(GET_AVAILABLE_VEHICLE_GROUPS, {
    fetchPolicy: "no-cache"
  });

  const [
    loadExternalVehicleGroups,
    { loading: externalVehicleGroupsLoading, data: externalAvailableVehicleGroupsData }
  ] = useLazyQuery(GET_EXTERNAL_AVAILABLE_VEHICLE_GROUPS, {
    fetchPolicy: "no-cache"
  });

  const [
    loadSubscriptionVehicles,
    { loading: subscriptionVehiclesLoading, data: availableSubscriptionVehiclesData }
  ] = useLazyQuery(GET_AVAILABLE_SUBSCRIPTION_VEHICLES, {
    fetchPolicy: "no-cache"
  });

  const remainder = 15 - d.now().minute % 15;
  const minPickupDateTime = d.now().plus({ minutes: remainder }).toJSDate()
  minPickupDateTime.setMilliseconds(0);
  minPickupDateTime.setSeconds(0);

  useEffect(() => {
    if (props.oneWayRentalFee) {
      setOneWayRentalFee(props.oneWayRentalFee);
    }
  }, [props.oneWayRentalFee]);

  useEffect(() => {
    if (userState && userState.currentBranch && userState.currentBranch.id) {
      if (userState.currentOrganisation.crossLocationBookingEnabled) {
        loadServiceLocationInOrg({
          variables: {
            organisationId: userState.currentOrganisation.id
          }
        })
      } else {
        loadBranchData({
          variables: {
            branchId: userState.currentBranch.id
          }
        });
      }
    }
  }, [userState]);

  useEffect(() => {
    if (props.bookingData) {
      setValues(props.bookingData);
      setRequiredFieldUpdated(false)
      setDifferentDropOffLocation(
        props.bookingData.pickupServiceLocation !==
        props.bookingData.dropoffServiceLocation
      );
      loadBusinessCustomers();
      if (props.bookingData.id) {
        setVehiclesSearched(true);
      }
      if (props.bookingData.isSubscription) {
        props.setBookingType(BookingType.SUBSCRIPTION)
      }
    }
  }, [props.bookingData]);

  useEffect(() => {
    if (businessCustomersData && businessCustomersData.businessCustomers) {
      setBusinessCustomers(businessCustomersData.businessCustomers);
      if (values.businessCustomer && values.id) {
        const businessCustomerData = businessCustomersData.businessCustomers.find(
          (customer: IBookingBusinessCustomer) => {
            return customer.id === values.businessCustomer;
          }
        );
        setBusinessCustomer(businessCustomerData);
      }
    }
  }, [businessCustomersData]);

  useEffect(() => {
    if (values?.businessCustomer) {
      const businessCustomerData = businessCustomers.find(
        (customer: IBookingBusinessCustomer) => {
          return customer.id === values?.businessCustomer;
        }
      );
      if (businessCustomerData) {
        setBusinessCustomer(businessCustomerData);
      }
    }
  }, [values, businessCustomers]);

  useEffect(() => {
    if (availableVehicleGroupsData && externalAvailableVehicleGroupsData && !vehicleGroupsLoading && !externalVehicleGroupsLoading) {
      setRequiredFieldUpdated(false);
      let arr: IVehiclePriceGroup[] = [];
      if (availableVehicleGroupsData.availableVehicleGroups?.vehicleGroups?.length) {
        const vgArr = availableVehicleGroupsData.availableVehicleGroups.vehicleGroups
        for (let i = 0; i < vgArr.length; i++) {
          const vg = vgArr[i];
          const idx = arr.findIndex((item) => item.id === vg.id);
          if (idx > -1) {
            vg.vehicles.forEach((vehicle: IVehicle) => {
              if (!arr[idx].vehicles.some(v => v.id === vehicle.id)) {
                arr[idx].vehicles.push(vehicle)
              }
            })
          } else {
            arr.push(vg)
          }
        }
      }
      if (externalAvailableVehicleGroupsData.externalAvailableVehicleGroups?.vehicleGroups?.length) {
        const eVgArr = externalAvailableVehicleGroupsData.externalAvailableVehicleGroups.vehicleGroups
        for (let i = 0; i < eVgArr.length; i++) {
          const eVg = eVgArr[i];
          const idx = arr.findIndex((item) => item.id === eVg.id);
          if (idx > -1) {
            eVg.vehicles.forEach((vehicle: IVehicle) => {
              if (!arr[idx].vehicles.some(v => v.id === vehicle.id)) {
                arr[idx].vehicles.push({
                  ...vehicle,
                  shared: true
                })
              }
            })
          } else {
            eVg.vehicles = eVg.vehicles.map((ele: IVehicle) => {
              return {
                ...ele,
                shared: true
              }
            })
            arr.push(eVg)
          }
        }
      }
      setLocationSurchargeRules(availableVehicleGroupsData.availableVehicleGroups.locationSurcharges);
      setOneWayRentalFee(availableVehicleGroupsData.availableVehicleGroups.oneWayRentalFee);
      setVehiclesSearched(true);

      if (userState.currentOrganisation.crossLocationBookingEnabled) {
        const vehiclesDistanceArr: any[] = _.unionBy(availableVehicleGroupsData.availableVehicleGroups.vehiclesDistance, externalAvailableVehicleGroupsData.externalAvailableVehicleGroups.vehiclesDistance, 'vehicleId');
        arr = arr.map((vg) => {
          for (let index = 0; index < vg.vehicles.length; index++) {
            const vehicle = vg.vehicles[index];
            for (let j = 0; j < vehiclesDistanceArr.length; j++) {
              const vd = vehiclesDistanceArr[j];
              if (vehicle.id === vd.vehicleId) {
                vg.vehicles[index] = {
                  ...vg.vehicles[index],
                  awayDistance: vd.distance
                }
              }
            }
          }
          vg.vehicles = _.sortBy(vg.vehicles, (v) => v.awayDistance);
          return vg;
        })
      }
      setVehicleGroups(arr)
      props.updateVehicleGroups(arr);
      props.updateLocationSurcharge(availableVehicleGroupsData.availableVehicleGroups.locationSurcharges);
      props.updateOneWayRentalFee(availableVehicleGroupsData.availableVehicleGroups.oneWayRentalFee);
      const flag = arr.every((vehicleGroup: IVehiclePriceGroup) => {
        const basePrice = vehicleGroup.basePrices.find((bp: IRate) => bp.rateTypeName === values.rateTypeName);
        if (basePrice) {
          if (basePrice.hasOwnProperty("applicableAmount") && typeof basePrice.applicableAmount === "number") {
            return basePrice.applicableAmount === 0;
          } else if (basePrice.hasOwnProperty("rate")) {
            return basePrice.rate === 0;
          }
        }
      });
      setIsPriceZero(flag)
    }
  }, [availableVehicleGroupsData, externalAvailableVehicleGroupsData, vehicleGroupsLoading, externalVehicleGroupsLoading])

  useEffect(() => {
    if (availableSubscriptionVehiclesData && availableSubscriptionVehiclesData.availableSubscriptionVehicles) {
      setRequiredFieldUpdated(false)
      setSubscriptionVehicles(availableSubscriptionVehiclesData.availableSubscriptionVehicles)
      props.updateSubscriptionVehicles(availableSubscriptionVehiclesData.availableSubscriptionVehicles)
      setVehiclesSearched(true)
    }
  }, [availableSubscriptionVehiclesData])

  useEffect(() => {
    if (props.subscriptionVehicles && props.subscriptionVehicles.length) {
      setSubscriptionVehicles(props.subscriptionVehicles)
      setVehiclesSearched(true)
    }
  }, [props.subscriptionVehicles])

  useEffect(() => {
    if (props.vehicleGroupsData && props.vehicleGroupsData.length) {
      setVehicleGroups(props.vehicleGroupsData);
      setVehiclesSearched(true);
    }
  }, [props.vehicleGroupsData]);

  useEffect(() => {
    if (props.locationSurchargesData && props.locationSurchargesData.length) {
      setLocationSurchargeRules(props.locationSurchargesData);
    }
  }, [props.locationSurchargesData]);

  useEffect(() => {
    if (values.dropoffDateTime && searchDisabled) {
      const endDate = getEndDateForBilling(values.pickupDateTime, values.rateTypeName)
      if (endDate <= values.dropoffDateTime) {
        setSearchDisabled(false)
      }
    }
  }, [values.dropoffDateTime, values.pickupDateTime])


  const baseDetailsSchema = Yup.object().shape({
    dropoffDateTime: Yup.string(),
    dropoffServiceLocation: Yup.string(),
    pickupDateTime: Yup.string()
      .required("Pick-up date is required.")
      .min(1),
    pickupServiceLocation: Yup.string()
      .required("Pick-up location is required.")
      .min(1)
  });

  const submitForm = (data: IBookingCreateInput) => {
    const pickup = d.fromISO(data.pickupDateTime);
    if (userState.role !== UserRoles.SUPER_ADMIN) {
      if (pickup < d.now()) {
        snackbar({
          message: "Pick-up date/time should be in future.",
          variant: SnackBarVariant.ERROR
        });
        return;
      }
      if (data.dropoffDateTime && pickup > d.fromISO(data.dropoffDateTime)) {
        snackbar({
          message: "Drop-off time should be greater than pick-up time.",
          variant: SnackBarVariant.ERROR
        });
        return;
      }
    }
    const valuesObj = { ...values };
    setRequiredFieldUpdated(false);
    if (!differentDropOffLocation) {
      data.dropoffServiceLocation = data.pickupServiceLocation;
      valuesObj.dropoffServiceLocation = data.pickupServiceLocation;
    }
    if (data.dropoffDateTime && d.now() > d.fromISO(data.dropoffDateTime)) {
      data.dropoffDateTime = undefined;
    }

    if (!data.dropoffDateTime) {
      data.longTermBooking = true;
      delete data.dropoffDateTime;
    }
    if (bookingType === BookingType.RENTAL) {
      loadVehicleGroups({
        variables: {
          args: {
            endDate: d.fromISO(data.dropoffDateTime || "").toUTC().toISO(),
            startDate: d.fromISO(data.pickupDateTime || "").toUTC().toISO(),
            rateTypeDuration: data.rateTypeDuration,
            longTerm: data.longTermBooking,
            businessCustomer: data.businessCustomer || "",
            bookingId: values.id || "",
            pcoNumber: data.pcoNumber,
            smartCarVehicle: data.smartCarVehicle,
            serviceLocation: data.pickupServiceLocation,
            dropoffServiceLocation: data.dropoffServiceLocation
          }
        }
      });
      loadExternalVehicleGroups({
        variables: {
          args: {
            endDate: d.fromISO(data.dropoffDateTime || "").toUTC().toISO(),
            startDate: d.fromISO(data.pickupDateTime || "").toUTC().toISO(),
            rateTypeDuration: data.rateTypeDuration,
            longTerm: data.longTermBooking,
            businessCustomer: data.businessCustomer || "",
            bookingId: values.id || "",
            pcoNumber: data.pcoNumber,
            smartCarVehicle: data.smartCarVehicle,
            serviceLocation: data.pickupServiceLocation
          }
        }
      });
      setValues({
        ...valuesObj,
        vehicleGroups: []
      });
    }
    else {
      loadSubscriptionVehicles({
        variables: {
          args: {
            endDate: d.fromISO(data.dropoffDateTime || "").toUTC().toISO(),
            startDate: d.fromISO(data.pickupDateTime || "").toUTC().toISO(),
            bookingId: values.id || ""
          }
        }
      })
      setValues({
        ...values,
        vehicleGroups: [],
        activeSubscription: {
          ...valuesObj.activeSubscription,
          startDate: data.pickupDateTime,
          endDate: data.dropoffDateTime
        },
        activeSubscriptionExpanded: {
          ...values.activeSubscription,
          startDate: data.pickupDateTime,
          endDate: data.dropoffDateTime
        }
      })
    }
  };

  const handleSubmit = (data: IBookingCreateInput, updateOnly?: boolean) => {
    props.onSubmit(data, updateOnly);
  };

  function getHolidays(startDate: d, endDate: d) {
    const datesArray = [];
    let dateIterator = startDate;
    while (dateIterator < endDate) {
      datesArray.push(getLocalizedDateFormat(country, dateIterator.toUTC().toISO(), DATE_TYPE.CONDENSED));
      dateIterator = dateIterator.plus({ days: 1 });
    }
    datesArray.push(getLocalizedDateFormat(country, endDate.toUTC().toISO(), DATE_TYPE.CONDENSED));
    return datesArray;
  }

  const getDisabledDates = () => {
    let disabledDatesArray: (number | string)[] = []
    if (userState && userState.currentBranch?.operatingHours && userState && userState.currentBranch.operatingHours.length) {
      for (const opHr of userState && userState.currentBranch.operatingHours) {
        if (opHr.closed) {
          disabledDatesArray.push(opHr.dayIndex)
        }
      }
    }
    if (userState && userState.currentBranch && userState && userState.currentBranch.holidays && userState && userState.currentBranch.holidays.length) {
      for (const holiday of userState && userState.currentBranch.holidays) {
        const dates = getHolidays(d.fromFormat(holiday.startDate, getLocalizedDateFormatString(country, DATE_TYPE.CONDENSED)), d.fromFormat(holiday.endDate, getLocalizedDateFormatString(country, DATE_TYPE.CONDENSED)));
        disabledDatesArray = [...disabledDatesArray, ...dates];
      }
    }
    return disabledDatesArray
  }

  const handleDateTimeChange = (fromDateTime: string, toDateTime: string) => {
    setValues({
      ...values,
      pickupDateTime: fromDateTime,
      dropoffDateTime: toDateTime
    });
    setRequiredFieldUpdated(true);
  }

  const toggleSubscription = (flag: boolean, rateTypeName: string) => {
    if (flag) {
      setValues({
        ...values,
        billingCycleDuration: rateTypeName === RATE_TYPES.WEEKLY ? 10080 : 43200,
        billingCycleName: rateTypeName,
        rateTypeDuration: rateTypeName === RATE_TYPES.WEEKLY ? 10080 : 43200,
        rateTypeName: rateTypeName
      });
    } else {
      setValues({
        ...values,
        billingCycleDuration: 1440,
        billingCycleName: RATE_TYPES.DAILY,
        rateTypeDuration: 1440,
        rateTypeName: RATE_TYPES.DAILY
      });
    }
  }

  const onSwitchBusinessType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBookingType(e.target.value)
    setVehicleGroups([])
    setSubscriptionVehicles([])
    props.updateVehicleGroups([])
    props.updateSubscriptionVehicles([])
  }

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <Formik
          enableReinitialize
          validationSchema={baseDetailsSchema}
          initialValues={values}
          onSubmit={(values, { setSubmitting }) => {
            submitForm(values);
            setSubmitting(false);
          }}
        >
          {(formikProps) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      component={TextField}
                      required
                      label={"Pick-Up Location"}
                      select
                      inputProps={{
                        onChange: (event: any) => {
                          setRequiredFieldUpdated(true);
                          setLocationSurchargeRules([]);
                          setValues({
                            ...values,
                            pickupServiceLocation: event.target.value
                          });
                        },
                        value: serviceLocations.length > 0 ? values.pickupServiceLocation : ""
                      }}
                      name={"pickupServiceLocation"}
                      fullWidth
                    >
                      {serviceLocations && serviceLocations.length &&
                        serviceLocations.sort((a: IServiceLocation, b: IServiceLocation) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                          .filter((sl) => {
                            if (userState.currentOrganisation.crossLocationBookingEnabled && sl.branch) {
                              return sl.branch.id === userState.currentBranch.id;
                            } else {
                              return sl;
                            }
                          })
                          .map(
                            (location: IServiceLocation, key: number) => {
                              return (
                                <MenuItem value={location.id} key={location.id}>
                                  {userState.currentOrganisation.crossLocationBookingEnabled ?
                                    `${location.name} - ${location.branch?.name}` : location.name}
                                </MenuItem>
                              );
                            }
                          )}
                    </Field>
                  </FormControl>
                </Grid>
                {differentDropOffLocation && (
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <FormControl variant="outlined" fullWidth>
                      <Field
                        component={TextField}
                        id="dropoff-location-select-outlined"
                        required
                        select
                        label={"Drop-off Location"}
                        inputProps={{
                          onChange: (event: any) => {
                            setRequiredFieldUpdated(true);
                            setValues({
                              ...values,
                              dropoffServiceLocation: event.target.value
                            });
                          },
                          value: serviceLocations && serviceLocations.length > 0 ? values.dropoffServiceLocation : ""
                        }}
                        name={"dropoffServiceLocation"}
                        fullWidth
                      >
                        {serviceLocations && serviceLocations.length &&
                          serviceLocations.map(
                            (location: IServiceLocation, key: number) => {
                              return (
                                <MenuItem value={location.id} key={location.id}>
                                  {userState.currentOrganisation.crossLocationBookingEnabled ?
                                    `${location.name} - ${location.branch?.name}` : location.name}
                                </MenuItem>
                              );
                            }
                          )}
                      </Field>
                    </FormControl>
                  </Grid>
                )}
                <DateTimePicker
                  fromDateTime={values.pickupDateTime || ""}
                  toDateTime={values.dropoffDateTime || ""}
                  handleChange={handleDateTimeChange}
                  required={true}
                  labels={values.tba ? ["Pick-up Date", "Pick-up Time", "Renewal Date", "Renewal Time"] : ["Pick-up Date", "Pick-up Time", "Drop-off Date", "Drop-off Time"]}
                  gridSpan={6}
                  gridSpanSm={6}
                  gridSpanMd={4}
                  gridSpanLg={2}
                  toDateChange={(isToDateSelected) => {
                    setToDateSelected(isToDateSelected);
                  }}
                  minDateTime={userState.role === UserRoles.SUPER_ADMIN ? d.now().minus({ months: 6 }).toUTC().toISO() : d.now().toUTC().toISO()}
                />
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      component={TextField}
                      name={"customerType"}
                      fullWidth
                      required
                      label={"Customer Type"}
                      type="text"
                      select
                      disabled={!!values.id}
                      inputProps={{
                        onChange: (event: any) => {
                          setRequiredFieldUpdated(true);
                          if (event.target.value === "individual") {
                            setValues({
                              ...values,
                              authorizedSignatory: "",
                              businessCustomer: "",
                              customerType: event.target.value
                            });
                            setBusinessCustomer(undefined)
                          } else {
                            setValues({
                              ...values,
                              customer: "",
                              customerType: event.target.value
                            });
                          }
                        },
                        value: formikProps.values.customerType
                      }}
                    >
                      {customerTypes.map((item: any, index: number) => {
                        return (
                          <MenuItem key={index} value={item.value}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </FormControl>
                </Grid>
                {formikProps.values.customerType === "business" && (
                  <>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <FormControl variant="outlined" fullWidth>
                        <Autocomplete
                          id="select-business-customer"
                          options={businessCustomers}
                          getOptionLabel={(
                            option: IBookingBusinessCustomer
                          ) => {
                            return option.businessName;
                          }}
                          style={{ width: "100%" }}
                          renderInput={(params: any) => (
                            <InputField
                              {...params}
                              value={
                                businessCustomer?.businessName
                                  ? businessCustomer.businessName
                                  : ""
                              }
                              label={"Business Account"}
                              variant="outlined"
                              required
                            />
                          )}
                          value={
                            businessCustomer && businessCustomer.id !== ""
                              ? businessCustomer
                              : null
                          }
                          disabled={!!values.id}
                          onChange={(event: any, newValue: any) => {
                            setRequiredFieldUpdated(true);
                            if (newValue && newValue.id && businessCustomers) {
                              const businessCustomerData = businessCustomers.find(
                                (customer: IBookingBusinessCustomer) => {
                                  return customer.id === newValue.id;
                                }
                              );
                              if (businessCustomerData) {
                                setBusinessCustomer(businessCustomerData);
                                setValues({
                                  ...values,
                                  businessCustomer: businessCustomerData.id
                                });
                              }
                            }
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={TextField}
                          name={"authorizedSignatory"}
                          fullWidth
                          required
                          label={"Authorised Signatory"}
                          type="text"
                          select
                          inputProps={{
                            onChange: (event: any) => {
                              const authorizedSignatory = businessCustomer?.authorizedSignatories.find(
                                (authSig) => {
                                  return authSig.id === event.target.value;
                                }
                              );
                              if (!authorizedSignatory) {
                                return;
                              }
                              setRequiredFieldUpdated(true);
                              setValues({
                                ...values,
                                authorizedSignatory: authorizedSignatory.id
                              });
                            },
                            value: values.authorizedSignatory
                              ? values.authorizedSignatory
                              : null
                          }}
                        >
                          {businessCustomer?.authorizedSignatories &&
                            businessCustomer.authorizedSignatories.map(
                              (signatory: any) => {
                                return (
                                  <MenuItem
                                    key={signatory.id}
                                    value={signatory.id}
                                  >
                                    {`${signatory.firstName} ${signatory.lastName}`}
                                  </MenuItem>
                                );
                              }
                            )}
                        </Field>
                      </FormControl>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      component={TextField}
                      name={"rateType"}
                      fullWidth
                      type="text"
                      select
                      label={"Rental Type"}
                      required
                      disabled={values.status === BookingStatus.CONFIRMED}
                      inputProps={{
                        onChange: (event: any) => {
                          setRequiredFieldUpdated(true);
                          setSearchDisabled(false)
                          rateTypes.forEach((rateType) => {
                            const name = rateType.rateTypeName;
                            if (
                              name === String(event.target.value).toLowerCase()
                            ) {
                              setValues({
                                ...values,
                                billingCycleDuration: rateType.rateTypeDuration,
                                billingCycleName: name,
                                rateTypeDuration: rateType.rateTypeDuration,
                                rateTypeName: name
                              });
                            }
                          });
                        },
                        value: values.rateTypeName
                          ? capitalizeFirstLetter(values.rateTypeName)
                          : "Daily"
                      }}
                    >
                      {rateTypes &&
                        rateTypes.map((rateType: any, key: number) => {
                          if (rateType.rateTypeName === RATE_TYPES.HOURLY) {
                            const weeklyEndDate = getEndDateForBilling(values.pickupDateTime, RATE_TYPES.WEEKLY);
                            if (values.dropoffDateTime && values.dropoffDateTime >= weeklyEndDate) {
                              if (values.rateTypeName === rateType.rateTypeName) {
                                setSearchDisabled(true)
                                setRequiredFieldUpdated(true)
                              }
                              return;
                            }
                          }
                          if ([RATE_TYPES.WEEKLY, RATE_TYPES.MONTHLY].includes(rateType.rateTypeName)) {
                            const endDate = getEndDateForBilling(values.pickupDateTime, rateType.rateTypeName)
                            const endDateStart = d.fromISO(endDate).startOf("day").toUTC().toISO();
                            if (!(values.dropoffDateTime && values.dropoffDateTime >= endDateStart)) {
                              if (values.rateTypeName === rateType.rateTypeName) {
                                setSearchDisabled(true)
                                setRequiredFieldUpdated(true)
                              }
                              return;
                            }
                          }
                          return (
                            <MenuItem
                              value={capitalizeFirstLetter(
                                rateType.rateTypeName
                              )}
                              key={key}
                            >
                              {capitalizeFirstLetter(rateType.rateTypeName)}
                            </MenuItem>
                          );
                        })}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.tba}
                          onChange={() => {
                            setValues({
                              ...formikProps.values,
                              tba: !values.tba
                            });

                          }}
                          value="tba"
                          color="secondary"
                        />
                      }
                      label={
                        <Typography variant={"h5"}>
                          {"Is TBA?"}
                        </Typography>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={differentDropOffLocation}
                          onChange={() => {
                            setValues({
                              ...formikProps.values,
                              dropoffServiceLocation:
                                values.pickupServiceLocation
                            });
                            setDifferentDropOffLocation(
                              !differentDropOffLocation
                            );
                          }}
                          value="differentDropOffLocation"
                          color="secondary"
                        />
                      }
                      label={
                        <Typography variant={"h5"}>
                          {"Return Location"}
                        </Typography>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            formikProps.values.pcoNumber
                              ? formikProps.values.pcoNumber
                              : false
                          }
                          onClick={() => {
                            setRequiredFieldUpdated(true);
                            setValues({
                              ...values,
                              pcoNumber: !formikProps.values.pcoNumber
                            });
                          }}
                          value="pcoNumber"
                          color="secondary"
                        />
                      }
                      label={
                        <Typography variant={"h5"}>
                          {"PCO Rental"}
                        </Typography>
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={2}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            formikProps.values.smartCarVehicle
                              ? formikProps.values.smartCarVehicle
                              : false
                          }
                          onClick={() => {
                            setRequiredFieldUpdated(true);
                            setValues({
                              ...values,
                              smartCarVehicle: !formikProps.values.smartCarVehicle
                            });
                          }}
                          value="smartCarVehicle"
                          color="secondary"
                        />
                      }
                      label={
                        <Typography variant={"h5"}>
                          {"Keyless Entry"}
                        </Typography>
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={2}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.zeroCostBooking}
                          onClick={() => {
                            setValues({
                              ...formikProps.values,
                              zeroCostBooking: !values.zeroCostBooking
                            });
                          }}
                          name="checkedB"
                          color="secondary"
                        />
                      }
                      label={
                        <Typography variant={"h5"}>
                          {`Test/Courtesy ${getLocalizedBookingSyntex(country)}`}
                        </Typography>
                      }
                      disabled={formikProps.values.id ? true : false}
                    />
                  </FormControl>
                </Grid>
                {
                  allowSubscriptionBookings && (
                    <Grid item xs={4} sm={4}>
                      <RadioGroup row value={bookingType} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSwitchBusinessType(e)}>
                        <FormControlLabel disabled={props.isUpdate} value={BookingType.RENTAL} control={<Radio />} label={"Rental Business"} />
                        <FormControlLabel disabled={props.isUpdate} value={BookingType.SUBSCRIPTION} control={<Radio />} label={"Subscription Business"} />
                      </RadioGroup>
                    </Grid>
                  )
                }
                <Grid
                  container
                  xs={getSearchButtonGridSize("xs", { customerType: formikProps.values.customerType, allowSubscriptionBookings, differentDropOffLocation, })}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="search"
                    type="submit"
                    disabled={
                      searchDisabled ||
                      !values.pickupServiceLocation ||
                      !values.pickupDateTime ||
                      d.fromISO(values.dropoffDateTime || "") <= d.fromISO(values.pickupDateTime) ||
                      (values.dropoffDateTime && getDisabledDates().includes(getLocalizedDateFormat(country, values.dropoffDateTime, DATE_TYPE.CONDENSED))) ||
                      (!values.billingCycleName && !values.dropoffDateTime) ||
                      (differentDropOffLocation &&
                        !values.dropoffServiceLocation) ||
                      (values.customerType === "business" &&
                        (Boolean(!values.businessCustomer) ||
                          Boolean(!values.authorizedSignatory)))
                    }
                  >
                    Search
                  </Fab>
                </Grid>
              </Grid>

            </Form>
          )}
        </Formik>
      </Paper>
      <Grid xs={12} item>
        <Box mt={1}></Box>
        {
          values.pcoNumber &&
          (
            <Typography variant="h4">
              Note: Only vehicles with PCO Number will be displayed below and on booking confirmation the vehicle documents will be sent along with the Booking Confirmation email.
            </Typography>
          )
        }
        {
          values.smartCarVehicle &&
          (
            <Typography variant="h4">
              Note: Only one vehicle can be selected per booking for Keyless Entry.
            </Typography>
          )
        }
      </Grid>
      {props.isUpdate && !vehiclesSearched && <CircularProgress />}
      {
        bookingType === BookingType.RENTAL && (
          (vehicleGroupsLoading || externalVehicleGroupsLoading) ? (
            <Grid style={{ margin: "0 auto", marginTop: "20px" }}>
              <CircularProgress />
            </Grid>
          ) : vehiclesSearched && vehicleGroups.length > 0 && isPriceZero ? (
            <Typography variant="h4">
              No vehicles found matching the selected criteria because of one or more conflicting price rules or vehicle price is zero.
            </Typography>
          ) : !requiredFieldUpdated ? (
            vehiclesSearched &&
            (vehicleGroups.length > 0 ? (
              <VehicleSelection
                vehicleGroupData={vehicleGroups}
                bookingData={values}
                onSubmit={handleSubmit}
                locationSurcharges={locationSurchargeRules}
                toggleSubscription={toggleSubscription}
                isUpdate={props.isUpdate}
                oneWayRentalFee={oneWayRentalFee}
                bookingType={bookingType}
                subscriptionVehicles={subscriptionVehicles}
                isLocationDisabled={isServiceLocationDisabled}
              />
            ) : (
              <Typography variant={"body1"} style={{ marginTop: "20px" }}>
                No vehicles found matching the selected criteria
              </Typography>
            ))
          ) : (
            <Typography variant={"body1"} style={{ marginTop: "20px" }}>
              Click "SEARCH" to get vehicles matching the selected criteria
            </Typography>
          )
        )
      }

      {
        bookingType === BookingType.SUBSCRIPTION && (
          (subscriptionVehiclesLoading) ? (
            <Grid style={{ margin: "0 auto", marginTop: "20px" }}>
              <CircularProgress />
            </Grid>
          ) : vehiclesSearched && !subscriptionVehicles.length ? (
            <Typography variant={"body1"} style={{ marginTop: "20px" }}>
              No vehicles found matching the selected criteria
            </Typography>
          ) : !requiredFieldUpdated ? (
            vehiclesSearched &&
            (subscriptionVehicles.length > 0 ? (
              <VehicleSelection
                vehicleGroupData={[]}
                bookingData={values}
                onSubmit={handleSubmit}
                locationSurcharges={locationSurchargeRules}
                toggleSubscription={toggleSubscription}
                isUpdate={props.isUpdate}
                oneWayRentalFee={oneWayRentalFee}
                bookingType={bookingType}
                subscriptionVehicles={subscriptionVehicles}
                isLocationDisabled={isServiceLocationDisabled}
              />
            ) : (
              <Typography variant={"body1"} style={{ marginTop: "20px" }}>
                No vehicles found matching the selected criteria
              </Typography>
            ))
          ) : (
            <Typography variant={"body1"} style={{ marginTop: "20px" }}>
              Click "SEARCH" to get vehicles matching the selected criteria
            </Typography>
          )
        )
      }
    </React.Fragment>
  );
};

export default BaseDetails;
